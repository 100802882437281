import React from 'react'
import { connect } from 'react-redux'
import {Route, Redirect} from 'react-router-dom'
import Header from '../components/Header'
import FooterPage from '../components/FooterPage'

export const PrivateRoute = ({
    isAuthenticated, 
    component: Component,
    ...rest
    }) => (
    <Route {...rest} component={(props) => (
        isAuthenticated ? (
            <div>
                <Header/>
                <Component {...props}/>
                <FooterPage/>
            </div>  
        ) : (
            <Redirect to='/' />
        )
    )}/>
)

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
})


export default connect(mapStateToProps)(PrivateRoute)

